import { useParams } from "react-router-dom";
import InvoicePDF from "components/PDF/Invoice/InvoicePDF";
import { useQuery } from "react-query";
import ApiClient from "api/v2/ApiClient";
import Loading from "pages/Loading/Loading";

export default function Invoice() {
  const params = useParams();
  const { data: invoiceData, isLoading } = useQuery({
    queryKey: ["invoice", params?.invoiceRef],
    queryFn: () =>
      ApiClient.account.getInvoice({
        ref: params?.invoiceRef ?? "",
        password: "",
      }),
    select: (data) => {
      return data.result;
    },
  });

  if (isLoading || !invoiceData) return <Loading />;

  return <InvoicePDF invoice={invoiceData} />;
}
