import { Grid } from "@mui/material";
import ApiClient from "api/v2/ApiClient";
import React from "react";
import { useQuery } from "react-query";
import Card from "@mui/material/Card";
import { Icon } from "@iconify/react";
import UpdateCreditsAmountModal from "./UpdateCreditsAmountModal/UpdateCreditsAmountModal";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { ROLES } from "utils/constants";
import { POSTTAG_ERRORS } from "utils/errors";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useAtom } from "jotai";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { NumberFormatter } from "utils/helpers";

type Props = {
  token: string;
  from: string;
  to: string;
};

export default function CreditUserCards({ token, from, to }: Props) {
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState);
  const [updateCreditsAmountModal, setUpdateCreditsAmountModal] =
    React.useState(false);
  const { data: creditData } = useQuery(["credits", token, from, to], {
    queryFn: () => ApiClient.stats.getTotalCredits({ paygId: token }),
    select: (data) => {
      if (data.status !== 200) return 0;
      return data.result;
    },
    enabled: !!token && !!from && !!to,
  });
  const { data: filteredRange, isLoading: isLoadingFilteredRange } = useQuery(
    ["filtered-range", token, from, to],
    {
      queryFn: () =>
        ApiClient.stats.getTotalFilters({
          activityType: 0,
          startDate: from,
          endDate: to,
          granularity: "Total",
          paygId: token,
          scope: company?.Scope ?? "User",
        }),
      select: (data) => {
        if (data.status !== 200) return {};
        if (data.message === POSTTAG_ERRORS.NO_DATA) return {};
        // @ts-ignore
        return data.result.find(Boolean);
      },
    }
  );

  return (
    <>
      <Grid container spacing={3} columns={12}>
        <Grid item md={4} xs={12} display="flex">
          <Card className="w-full p-4 pb-8 flex relative items-end gap-1">
            <p className="text-secondary text-sm">
              {company?.Type === ROLES.OPERATOR
                ? "Total Credits (All Users)"
                : "Credits Used"}
            </p>
            <p className="font-bold text-2xl">
              {NumberFormatter(filteredRange?.["Credit"] ?? 0)}
            </p>
          </Card>
        </Grid>
        <Grid item md={4} xs={12} display="flex">
          <Card className="w-full p-4 pb-8 flex relative items-end gap-1">
            <p className="text-secondary text-sm">
              {company?.Type === ROLES.OPERATOR
                ? "Total API Usage (All Users)"
                : "Account API Usage"}
            </p>
            <p className="font-bold text-2xl">
              {NumberFormatter(filteredRange?.["Total"] ?? 0)}
            </p>
          </Card>
        </Grid>
        <Grid item md={4} xs={12} display="flex">
          <Card className="w-full p-4 pb-8 flex relative items-end gap-1">
            <p className="text-secondary w-full sm:w-3/4 text-right text-sm">
              {company?.Type === ROLES.OPERATOR
                ? "Account Credits Remaining (Personal Account)"
                : "Credits Remaining"}
            </p>
            <p className="font-bold text-2xl">
              {NumberFormatter(creditData) ?? 0}
            </p>
            {user?.type === ROLES.ADMIN && (
              <button
                onClick={() => {
                  setUpdateCreditsAmountModal(true);
                }}
                className="absolute top-3 left-3  "
              >
                <Icon
                  icon="material-symbols:settings"
                  className="text-3xl text-primary"
                />
              </button>
            )}
          </Card>
        </Grid>
      </Grid>
      {user?.type === ROLES.ADMIN && (
        <UpdateCreditsAmountModal
          credits={creditData ?? 0}
          handleClose={() => setUpdateCreditsAmountModal(false)}
          open={updateCreditsAmountModal}
        />
      )}
    </>
  );
}
