import { Suspense, useEffect, useRef } from "react";
import GenericLayout from "layouts/GenericLayout";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import PriceCards from "pages/Dashboard/components/PriceCards/PriceCards";
import { toast } from "react-toastify";
import ApiUsageChart from "pages/Dashboard/components/ApiUsageChart/ApiUsageChart";
import { useSelector } from "react-redux";
import { authState, dismissNotice } from "store/authSlice";
import DashboardShimmer from "pages/Dashboard/components/Shimmer/DashboardShimmer";
import ActivityBreakdownCarousel from "./components/ActivityBreakdownCarousel/ActivityBreakdownCarousel";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { Authorization } from "components/Authorization";
import { ROLES } from "utils/constants";
import CreditUserCards from "pages/Dashboard/components/Cards/Cards";
import SubscriptionUserCards from "./components/Cards/SubscriptionUserCards";
import NoticePopup from "layouts/GenericLayout/NoticePopup";
import { UserAuthSlice } from "types/ReduxTypes";
import { useDispatch } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  const priceCardsRef = useRef<HTMLDivElement | null>(null);
  const { user } = useSelector(authState) as UserAuthSlice;
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const overviewRef = useRef(null);
  const accountRef = useRef(null);

  const toDate = searchParams.get("to");
  const fromDate = searchParams.get("from");
  const granularity = searchParams.get("granularity");

  useEffect(() => {
    if (location.search.includes("success")) {
      toast.success("Purchase successful.");
      dispatch(dismissNotice());
    }
    if (location.search.includes("fail")) {
      toast.error("Purchase unsuccessful.");
    }
    if (location.hash.includes("purchase-credits-card")) {
      setTimeout(() => {
        priceCardsRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [location.search, location.hash]);

  return (
    <GenericLayout
      showFilterBar
      routeRefs={{
        // @ts-ignore
        Overview: overviewRef ? overviewRef : null,
        Account: accountRef ? accountRef : null,
      }}
    >
      <Suspense fallback={<DashboardShimmer />}>
        <div className="flex flex-col gap-y-8">
          {company && toDate && fromDate && (
            <>
              <Authorization
                additionalCheck={company["Type"] !== ROLES.SUBSCRIPTION_USER}
                allowedRoles={[
                  ROLES.ADMIN,
                  ROLES.USER,
                  ROLES.OPERATOR,
                  ROLES.CREDIT_USER,
                  ROLES.PLUGIN_USER,
                ]}
              >
                <CreditUserCards
                  from={fromDate}
                  to={toDate}
                  token={company ? company["PAYG_ID"] : user.payId ?? ""}
                />
              </Authorization>

              <Authorization
                additionalCheck={company["Type"] === ROLES.SUBSCRIPTION_USER}
                allowedRoles={[
                  ROLES.ADMIN,
                  ROLES.SUBSCRIPTION_USER,
                  ROLES.OPERATOR,
                ]}
              >
                <SubscriptionUserCards
                  from={fromDate}
                  to={toDate}
                  token={company ? company["PAYG_ID"] : user.payId ?? ""}
                />
              </Authorization>

              <ApiUsageChart
                scope={company.Scope ?? "User"}
                token={company ? company?.["PAYG_ID"] : user.payId}
                from={fromDate}
                to={toDate}
                gran={granularity}
              />
              <ActivityBreakdownCarousel
                scope={company ? company.Scope : "User"}
                endDate={toDate ?? ""}
                granularity={granularity ?? "monthly"}
                paygId={company ? company.PAYG_ID : ""}
                startDate={fromDate ?? ""}
              />
              <Authorization
                allowedRoles={[
                  ROLES.CREDIT_USER,
                  ROLES.ADMIN,
                  ROLES.OPERATOR,
                  ROLES.PLUGIN_USER,
                  ROLES.USER,
                ]}
              >
                <div ref={priceCardsRef} className="">
                  <PriceCards />
                </div>
              </Authorization>
            </>
          )}
        </div>
      </Suspense>
      {user?.notice && (
        <NoticePopup
          open={true}
          handleClose={() => dispatch(dismissNotice())}
          notice={user.notice}
        />
      )}
    </GenericLayout>
  );
}
