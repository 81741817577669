import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div className="w-full h-screen flex justify-center flex-col gap-5 items-center">
      <h1 className="text-4xl font-bold text-primary">
        It seems that something went wrong.
      </h1>
      <Link
        to="/overview"
        type="button"
        className="px-8 py-4 rounded-lg bg-primary text-white"
      >
        Go back
      </Link>
    </div>
  );
}
