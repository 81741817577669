import { Text, View } from "@react-pdf/renderer";
import { InvoiceStylesheet } from "./useStyles";

type Props = {
  styles: InvoiceStylesheet;
  billingInfo: {
    type: string;
    id: string;
    billingPeriod: string;
    nextBillingDate: string | null;
    card: {
      expiryMonth: string;
      expiryYear: string;
      lastFourDigits: string;
    };
  };
  user: {
    companyName: string;
    address1: string;
    address2: string;
    address3: string;
    town: string;
    postcode: string;
    vatReg: string;
  };
};

export default function InvoiceBillingInfo({
  styles,
  billingInfo,
  user,
}: Props) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Text style={styles.bold}>BILLED TO</Text>
        <Text>{user.companyName}</Text>
        <Text>{user.address1}</Text>
        <Text>{[user.address2, user.address3].filter(Boolean).join(", ")}</Text>
        <Text>{user.town}</Text>
        <Text>{user.postcode}</Text>
        <Text>
          Vat Reg #:
          <Text style={styles.bold}>{user.vatReg}</Text>
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text style={styles.bold}>
            {billingInfo.type.includes("PAYG") ? "PAYG" : "SUBSCRIPTION"}
          </Text>
          <Text>
            Card Number{" "}
            <Text style={styles.bold}>
              **** **** **** {billingInfo.card.lastFourDigits}
            </Text>
          </Text>
          <Text>
            Card Expiry{" "}
            <Text style={styles.bold}>
              {billingInfo.card.expiryMonth}/{billingInfo.card.expiryYear}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
}
