import { Text, View, Image } from "@react-pdf/renderer";
// @ts-ignore
import logo from "assets/images/posttag-worded-logo.png";
import { InvoiceStylesheet } from "./useStyles";
import { InvoiceTransactionDetails } from "api/v2/Account/types";
import dayjs from "dayjs";

type Props = {
  styles: InvoiceStylesheet;
  invoice: {
    number: string;
    date: string;
    amount: string;
    customerId: string;
    paid: boolean;
    email: string | null;
  };
  posttagDetails: {
    companyName: string;
    address1: string;
    address2: string;
    address3: string;
    town: string;
    county: string;
    postcode: string;
    country: string;
    vatReg: string;
    logo: string;
  };
  transationDetails: InvoiceTransactionDetails;
};

export default function InvoiceTitle({
  styles,
  invoice,
  transationDetails,
  posttagDetails,
}: Props) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Image
          style={{
            marginBottom: 20,
            height: 50,
            width: 150,
          }}
          src={posttagDetails.logo || logo}
        />
        <Text>{posttagDetails.companyName}</Text>
        <Text>{posttagDetails.address1}</Text>
        <Text>
          {[posttagDetails.address2, posttagDetails.address3]
            .filter(Boolean)
            .join(", ")}
        </Text>
        <Text>{posttagDetails.town}</Text>
        <Text>
          {[posttagDetails.county, posttagDetails.country]
            .filter(Boolean)
            .join(", ")}
        </Text>
        <Text>{posttagDetails.postcode}</Text>

        <Text>
          Vat Reg #:
          <Text style={styles.bold}>{posttagDetails.vatReg}</Text>
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text style={styles.reportTitle}>TAX INVOICE</Text>
          <Text>
            Transaction Reference{" "}
            <Text style={styles.bold}>{invoice.number}</Text>
          </Text>
          <Text>
            Payment Date{" "}
            <Text style={styles.bold}>
              {dayjs(transationDetails.transactionTime).format("DD/MM/YYYY")}
            </Text>
          </Text>
          <Text>
            Invoice Amount <Text style={styles.bold}>{invoice.amount}</Text>
          </Text>
          <Text>
            Customer ID <Text style={styles.bold}>{invoice.customerId}</Text>
          </Text>
          {invoice.email && (
            <Text>
              Email <Text style={styles.bold}>{invoice.email}</Text>
            </Text>
          )}
        </View>
        <View
          style={{
            fontSize: 14,
            fontWeight: 800,
            color: invoice.paid ? "green" : "red",
          }}
        >
          <Text>{invoice.paid ? "Paid" : "Unpaid"}</Text>
        </View>
      </View>
    </View>
  );
}
